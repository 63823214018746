import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import styles from './AddBooking.module.css';
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import bookingImg from '../../../assets/img/images/bookingimg.png';
import confirmImg from '../../../assets/img/images/confirm3.png';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import colordata from '../../../data/colors.json';
import custom_fields from '../../../data/custom_fields.json';
import {
    ArrowDown,
    ArrowRight,
    Calendar,
    Check,
    CheckCircle,
    X,
} from 'react-feather';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useUser } from '../../../contexts/UserContext';
import Loader from '../Components/Loader';
import moment from 'moment';
import {
    StandaloneSearchBox,
    LoadScript,
    useLoadScript,
} from '@react-google-maps/api';
import { remove, set } from 'lodash';
import UploadHelper from '../../../utils/UploadHelper';
import { fi } from 'date-fns/locale';
import UploadHelper2 from '../../../utils/UploadHelper2';
function formatNumber(number) {
    return String(number).padStart(3, '0');
}
function truncateTextByWordCount(text, wordLimit) {
    console.log(text.length);
    if (text.length > wordLimit) {
        return text.slice(0, wordLimit) + '...';
    } else {
        console.log(text);
        return text;
    }
}
const findColorForKey = (key, position) => {
    const item = colordata.find((obj) => obj[key] !== undefined);
    return item ? item[key][position] : '';
};
const inputFiledsForKey = (key, position) => {
    console.log(custom_fields);
    const item = custom_fields.find((obj) => obj[key] !== undefined);
    return item ? item[key][position] : [];
};

const AddBooking = () => {
    const [slotsAvailable, setSlotsAvailable] = useState(null);
    const [submit, setSubmit] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState('');
    const [saveloader, setSaveLoader] = useState(false);
    const [invoiceno, setInvoiceno] = useState('001');
    const [pickModal, setPickModal] = useState(false);
    const [modalselected, setModalSelected] = useState(new Date());
    const [monthlySlots, setMonthlySlots] = useState([]);
    const [modalselectedmonth, setModalseletedmonth] = useState(new Date());
    const [loadingmonthlyslots, setLoadingmonthlyslots] = useState(false);
    const [selecteddaySlots, setSelecteddaySlots] = useState([]);
    const [billingadd, setbillingadd] = useState({});
    const [slotsCount, setSlotsDailyCount] = useState([]);
    const [tempSlots, setTempSlots] = useState([]);
    const fullHostname = window.location.hostname;
    const hostnameParts = fullHostname.split('.');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [initialValues, setInitialValues] = useState([
        {
            firstName: '',
            lastName: '',
            companyName: '',
            email: '',
            phoneNumber: '',
            description: '',
            // pricelist: "",
        },
    ]);
    // const subdomian = 'inova';
    const subdomian = hostnameParts[0];
    const inputRef = useRef();
    const custom_input = inputFiledsForKey(subdomian, 'custom_fields');
    const remove_input = inputFiledsForKey(subdomian, 'remove_fields');

    console.log(custom_input, remove_input);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyB2NVHakbiwVQsxEMJw0LnhwN6C6vuzEZE',
        libraries: ['places'],
    });

    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        if (place) {
            let country = place.address_components.filter((elem) =>
                elem.types.includes('country')
            );
            let state = place.address_components.filter((elem) =>
                elem.types.includes('administrative_area_level_1')
            );
            let city = place.address_components.filter((elem) =>
                elem.types.includes('locality')
            );
            let street = place.address_components.filter(
                (elem) =>
                    elem.types.includes('street_number') ||
                    elem.types.includes('route') ||
                    elem.types.includes('sublocality')
            );
            let zip = place.address_components.filter((elem) =>
                elem.types.includes('postal_code')
            );
            setbillingadd({
                country: country.length !== 0 ? country[0].long_name : '',
                state: state.length !== 0 ? state[0].long_name : '',
                city: city.length !== 0 ? city[0].long_name : '',
                zip_code: zip.length !== 0 ? zip[0].long_name : '',
                street:
                    street.length !== 0
                        ? street.map((elem) => elem.long_name).join(' ')
                        : '',
            });
        }
    };

    const { user } = useUser();
    const points = [
        'Interactive demo',
        'Product experts',
        'Flexible times',
        'No sales people',
    ];
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function generateTimeSlots(startTimeMillis, endTimeMillis) {
        const slotDurationMillis = 60 * 60 * 1000;
        const timeSlots = [];

        const adjustedStartTime = new Date(startTimeMillis);
        if (adjustedStartTime.getMinutes() !== 0) {
            adjustedStartTime.setMinutes(0, 0, 0);
            adjustedStartTime.setHours(adjustedStartTime.getHours() + 1);
        }

        for (
            let currentSlotStart = adjustedStartTime.getTime();
            currentSlotStart + slotDurationMillis <= endTimeMillis;
            currentSlotStart += slotDurationMillis
        ) {
            const slotEnd = currentSlotStart + slotDurationMillis;
            if (startTimeMillis === 1693891800000) {
                console.log(currentSlotStart, startTimeMillis);
                console.log(
                    moment(currentSlotStart).toString(),
                    moment(startTimeMillis).toString()
                );
            }
            if (
                currentSlotStart >= startTimeMillis &&
                slotEnd <= endTimeMillis
            ) {
                const slotStart = new Date(currentSlotStart);
                const slotEndTime = new Date(slotEnd);

                // if (
                //   slotStart.getHours() >= 8 &&
                //   slotStart.getHours() <= 20 // &&
                //   // slotStart.getDay() !== 0 &&
                //   // slotStart.getDay() !== 6
                // ) {
                timeSlots.push({
                    start: slotStart.getTime(),
                    end: slotEndTime.getTime(),
                });
                // }
            }
        }

        return timeSlots;
    }

    function getRandomSlotFromLatestDate(slotsArray) {
        // Group slots by the latest date
        const slotsByDate = slotsArray.reduce((groups, slot) => {
            const slotDate = new Date(slot.start);
            const dateString = slotDate.toDateString();

            if (!groups[dateString]) {
                groups[dateString] = [];
            }

            groups[dateString].push(slot);
            return groups;
        }, {});

        // Select a random slot from each group
        const selectedSlots = [];
        for (const dateGroup of Object.values(slotsByDate)) {
            if (dateGroup.length > 0) {
                const randomIndex = Math.floor(
                    Math.random() * dateGroup.length
                );
                selectedSlots.push(dateGroup[randomIndex]);
            }
        }

        return selectedSlots;
    }

    async function getData(startTime, EndTime) {
        const fullHostname = window.location.hostname;
        const hostnameParts = fullHostname.split('.');
        // const subdomian = 'inova';
        const subdomian = hostnameParts[0];

        const res = await fetch(
            `https://bestmate.us/portal/findEmptySlots/${subdomian}/${startTime}/${EndTime}`
        );

        if (res.status >= 200 && res.status < 300) {
            let data = await res.json();
            if (
                data &&
                Object.keys(data).length !== 0 &&
                Object.keys(data).includes('emptyTimeSlots') &&
                Object.keys(data.emptyTimeSlots).length !== 0
            ) {
                const timeSlots = data.emptyTimeSlots
                    .map((elem) => [
                        ...generateTimeSlots(elem.startTime, elem.endTime),
                    ])
                    .flat();

                const selectedSlots = getRandomSlotFromLatestDate(timeSlots);
                const finalSlot = selectedSlots.slice(0, 3);

                if (
                    data &&
                    Object.keys(data).length !== 0 &&
                    Object.keys(data).includes('highestRequestNumber')
                ) {
                    setInvoiceno(data.highestRequestNumber + 1);
                }
                let arr = [];

                setTempSlots((old) => {
                    arr = [...old, ...finalSlot];
                    console.log(arr.length);
                    return arr;
                });
                if (arr.length >= 4) {
                    setSlotsAvailable(arr.slice(0, 3));
                } else {
                    let newstart = EndTime;
                    let newend = moment(newstart).add(7, 'days').valueOf();
                    getData(newstart, newend);
                }
                // setSlotsAvailable(finalSlot);
            } else {
                let newstart = EndTime;
                let newend = moment(newstart).add(7, 'days').valueOf();
                getData(newstart, newend);
            }
        }
    }
    async function getMonthlySlots(startTime, EndTime) {
        const fullHostname = window.location.hostname;
        const hostnameParts = fullHostname.split('.');
        // const subdomian = 'inova';
        const subdomian = hostnameParts[0];
        console.log('fetching');
        const res = await fetch(
            `https://bestmate.us/portal/findEmptySlots/${subdomian}/${startTime}/${EndTime}`
        );

        if (res.status >= 200 && res.status < 300) {
            let data = await res.json();
            if (
                data &&
                Object.keys(data).length !== 0 &&
                Object.keys(data).includes('emptyTimeSlots') &&
                Object.keys(data.emptyTimeSlots).length !== 0
            ) {
                const timeSlots = data.emptyTimeSlots
                    .map((elem) => [
                        ...generateTimeSlots(elem.startTime, elem.endTime),
                    ])
                    .flat();

                setMonthlySlots(timeSlots);
                setLoadingmonthlyslots(false);
                console.log(new Date(startTime), new Date(EndTime), timeSlots);
            } else {
            }
        }
    }

    useEffect(() => {
        const startTime = moment(new Date()).valueOf();
        const EndTime = moment(startTime).add(7, 'days').valueOf();
        getData(startTime, EndTime);
    }, []);

    useEffect(() => {
        if (pickModal) {
            console.log('fetching month slot');
            const today = new Date();
            const firstDayOfMonth = new Date(
                today.getFullYear(),
                today.getMonth(),
                1
            );
            const firstDayOfModalselectedMonth = new Date(
                modalselectedmonth.getFullYear(),
                modalselectedmonth.getMonth(),
                1
            );
            const firstDayOfModalselected = new Date(
                modalselected.getFullYear(),
                modalselected.getMonth(),
                1
            );
            console.log(
                firstDayOfMonth.toString(),
                firstDayOfModalselectedMonth.toString(),
                firstDayOfModalselected.toString()
            );
            if (
                firstDayOfMonth.toString() ===
                firstDayOfModalselectedMonth.toString()
                // &&
                // firstDayOfModalselectedMonth.toString() ===
                //   firstDayOfModalselected.toString()
            ) {
                const startTime = moment(new Date()).valueOf();
                const EndTime = moment(
                    new Date(today.getFullYear(), today.getMonth() + 1, 0)
                )
                    .add(1, 'day')
                    .valueOf();
                getMonthlySlots(startTime, EndTime);
            } else {
                const start = new Date(
                    modalselectedmonth.getFullYear(),
                    modalselectedmonth.getMonth(),
                    1
                );
                const startTime = moment(start).valueOf();
                const EndTime = moment(startTime).add(1, 'month').valueOf();
                console.log(modalselectedmonth);
                getMonthlySlots(startTime, EndTime);
            }
        }
    }, [pickModal, modalselectedmonth]);

    useEffect(() => {
        const firstDayOfModalselectedMonth = new Date(
            modalselectedmonth.getFullYear(),
            modalselectedmonth.getMonth(),
            1
        );
        const firstDayOfModalselected = new Date(
            modalselected.getFullYear(),
            modalselected.getMonth(),
            1
        );
        if (
            !loadingmonthlyslots &&
            firstDayOfModalselectedMonth.toString() ===
                firstDayOfModalselected.toString()
        ) {
            let dat = monthlySlots.filter((slot) => {
                const slotDate = new Date(slot.start);
                // console.log(slotDate);
                return (
                    slotDate.getFullYear() === modalselected.getFullYear() &&
                    slotDate.getMonth() === modalselected.getMonth() &&
                    slotDate.getDate() === modalselected.getDate()
                );
            });
            console.log(dat);
            setSelecteddaySlots(dat);
        }
    }, [loadingmonthlyslots, modalselected]);

    const submitHandler = async (elem) => {
        setSaveLoader(true);
        const fullHostname = window.location.hostname;
        const hostnameParts = fullHostname.split('.');
        // const subdomian = 'inova';
        const subdomian = hostnameParts[0];
        let custom_save = [];
        let {
            firstName,
            lastName,
            companyName,
            description,
            email,
            phoneNumber,
            ...extra_fields
        } = elem;
        console.log(extra_fields);
        custom_input.forEach((field, index) => {
            const key = `${field.input_type}${index}`;
            let value = extra_fields[key];
            if (field.input_type !== 'header' && value && value.length !== 0) {
                custom_save.push({
                    title: field.title,
                    input_type: field.input_type,
                    value: value,
                });
            }
        });
        console.log(custom_save);
        const partnerData = {
            partner_first_name: capitalizeFirstLetter(elem.firstName),
            partner_last_name: capitalizeFirstLetter(elem.lastName),
            partner_email: elem.email,
            partner_company_name: elem.companyName,
            partner_number: elem.phoneNumber,
            partner_billing_address: {
                street: billingadd.street,
                city: billingadd.city,
                state: billingadd.state,
                zip_code: billingadd.zip_code,
                country: billingadd.country,
            },
            partner_shipping_address: {
                street: billingadd.street,
                city: billingadd.city,
                state: billingadd.state,
                zip_code: billingadd.zip_code,
                country: billingadd.country,
            },
            partner_type: 'LEAD',
            // partner_request: elem.description,
            business_app_name: subdomian,
        };
        console.log(partnerData);
        let partnersave = await fetch('https://bestmate.us/portal/createLead', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(partnerData),
        });
        // console.log(partnersave);
        if (partnersave.status >= 200 && partnersave.status < 300) {
            let json = await partnersave.json();
            console.log(json);
            let jobData = {
                // job_send_confirmation: true,
                invoice_currency_code: 'USD',
                invoice_currency_name: 'US Dollar',
                invoice_description: elem.description,
                invoice_created_at: new Date().getTime(),
                invoice_number: formatNumber(invoiceno),
                invoice_partner: {
                    // ...partnerData,
                    ...json,
                },
                invoice_custom_fields: custom_save,
                invoice_partner_id: json.partner_id,

                invoice_partner_type: json.partner_type,
                invoice_title: 'New Request from Portal',
                invoice_visits:
                    selectedSlot && Object.keys(selectedSlot).length !== 0
                        ? [
                              {
                                  appointment_start_time: selectedSlot.start,
                                  appointment_end_time: selectedSlot.end,
                                  appointment_zone_offset: -(
                                      new Date().getTimezoneOffset() * 60
                                  ),
                              },
                          ]
                        : [],
                invoice_workflow_type: 'REQUEST',
                business_app_name: subdomian,
                invoice_zone_offset: -(new Date().getTimezoneOffset() * 60),
                //other fields
                invoice_attachments: [],
                invoice_discount_rate: 0,
                invoice_discount_type: 'percentage',
                invoice_tax_type: 'percentage',
                invoice_grand_total: 0,
                invoice_order: [],
                invoice_settled_amount: 0,
                invoice_tax_rate: 0,
            };

            console.log(jobData);
            let jobSave = await fetch(
                'https://bestmate.us/portal/submitRequest',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(jobData),
                }
            );
            if (jobSave.status >= 200 && jobSave.status < 300) {
                setSubmit(true);
                setSaveLoader(false);
            } else {
                setSaveLoader(false);
                alert('Something went Wrong!');
                return;
            }

            // let res = await jobSave.json();

            // console.log(res);
        } else {
            setSaveLoader(false);
            alert('Something went Wrong!');
            return;
        }
    };
    // let disabledDays = {
    //   before: new Date(),
    // };
    let disabledDays = (date) => {
        const currentDate = new Date();

        return (
            loadingmonthlyslots ||
            (date.getDate() !== currentDate.getDate() && date < currentDate)
        );
    };

    useEffect(() => {
        if (custom_input && custom_input.length !== 0) {
            let obj = {};
            custom_input.forEach((elem, index) => {
                console.log(elem);
                if (elem.input_type !== 'header') {
                    if (
                        elem.input_type === 'short_free_form' ||
                        elem.input_type === 'long_free_form'
                    ) {
                        obj[`${elem.input_type}${index}`] = '';
                    } else if (
                        elem.input_type === 'multi_select' ||
                        elem.input_type === 'single_select' ||
                        elem.input_type === 'image_upload'
                    ) {
                        obj[`${elem.input_type}${index}`] = [];
                    }
                }
            });
            console.log(obj);
            setInitialValues((old) => {
                console.log({ ...old[0], ...obj });
                return { ...old[0], ...obj };
            });
        }
    }, [custom_input]);

    const validationSchema = () => {
        const schemaFields = {
            firstName: Yup.string()
                .required('First Name is required')
                .max(100, `First Name must be less than 100 characters`),
            lastName: Yup.string()
                .required('Last Name is required')
                .max(100, `Last Name must be less than 100 characters`),
            phoneNumber: Yup.string()
                .required('Phone is required')
                .matches(/^[0-9+\-\s]+$/, 'Phone number is not valid'),
            email: Yup.string()
                .email('Must be a Valid Email')
                .required('Email is required'),
        };
        custom_input.forEach((field, index) => {
            if (field.input_type !== 'header') {
                const fieldName = `${field.input_type}${index}`;
                let fieldSchema;

                switch (field.input_type) {
                    case 'single_select':
                    case 'multi_select':
                        fieldSchema = Yup.array().of(Yup.string());
                        break;
                    case 'short_free_form':
                    case 'long_free_form':
                        fieldSchema = Yup.string();
                        break;
                    case 'image_upload':
                        fieldSchema = Yup.array().of(
                            Yup.object().shape({
                                image: Yup.string().required(),
                            })
                        );
                        break;
                    default:
                        fieldSchema = Yup.mixed();
                }
                if (!field.optional) {
                    if (field.input_type === 'image_upload') {
                        fieldSchema = fieldSchema
                            .required(`Image is required`)
                            .min(1, `At least one Image is required`);
                    } else if (
                        field.input_type === 'single_select' ||
                        field.input_type === 'multi_select'
                    ) {
                        fieldSchema = fieldSchema
                            .required(`This Input is required`)
                            .min(1, `At least one option must be selected`);
                    } else {
                        fieldSchema = fieldSchema.required(
                            `This Input is required`
                        );
                    }
                } else if (field.input_type === 'image_upload') {
                    // For optional image uploads, ensure the array exists but can be empty
                    fieldSchema = fieldSchema.default([]);
                }

                schemaFields[fieldName] = fieldSchema;
            }
        });
        return Yup.object().shape(schemaFields);
    };

    return (
        <>
            <div
                className={styles.appbody}
                style={{
                    backgroundColor: `${
                        findColorForKey(subdomian, 'secondary2') || '#378CD5'
                    }`,
                }}
            >
                <div
                    style={{
                        padding: 32,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {user.business && user.business.business_logo ? (
                        <div style={{ marginRight: 20 }}>
                            <img
                                style={{ maxHeight: 60, maxWidth: 60 }}
                                src={`${user.business.business_logo}`}
                                alt='Logo'
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                    <div
                        className={styles.logo}
                        style={{ wordBreak: 'break-word' }}
                    >
                        {user.business && user.business.business_name
                            ? truncateTextByWordCount(
                                  user.business.business_name,
                                  30
                              )
                            : user.business.business_user_name
                            ? truncateTextByWordCount(
                                  user.business.business_user_name,
                                  30
                              )
                            : 'Business Name'}
                    </div>
                </div>

                {!submit ? (
                    <div className={styles.cardContainer}>
                        {slotsAvailable && slotsAvailable.length !== 0 ? (
                            <Card
                                className='border rounded-2'
                                style={{
                                    maxWidth: '880px',
                                    width: '100%',
                                    marginRight: 20,
                                    marginLeft: 20,
                                    backgroundColor: `${
                                        findColorForKey(
                                            subdomian,
                                            'primary2'
                                        ) || 'white'
                                    }`,
                                }}
                            >
                                <Row>
                                    <Col lg={12}>
                                        <Formik
                                            validationSchema={validationSchema()}
                                            validateOnChange={false}
                                            validateOnBlur={false}
                                            onSubmit={(values) => {
                                                submitHandler(values);
                                            }}
                                            initialValues={initialValues}
                                        >
                                            {({
                                                handleSubmit,
                                                handleChange,
                                                handleBlur,
                                                values,
                                                touched,
                                                isValid,
                                                errors,
                                                dirty,
                                                setFieldValue,
                                                setFieldTouched,
                                                setErrors,
                                            }) => (
                                                <Form
                                                    noValidate
                                                    onSubmit={(event) => {
                                                        event.preventDefault();
                                                        validationSchema()
                                                            .validate(values, {
                                                                abortEarly: false,
                                                            })
                                                            .then(() => {
                                                                // If validation passes, call handleSubmit
                                                                handleSubmit();
                                                            })
                                                            .catch((err) => {
                                                                setShowErrorModal(
                                                                    true
                                                                );
                                                                const validationErrors =
                                                                    {};
                                                                err.inner.forEach(
                                                                    (error) => {
                                                                        validationErrors[
                                                                            error.path
                                                                        ] =
                                                                            error.message;
                                                                    }
                                                                );
                                                                console.log(
                                                                    validationErrors,
                                                                    validationSchema()
                                                                );
                                                                // Update errors and touched fields in Formik
                                                                setErrors(
                                                                    validationErrors
                                                                );

                                                                Object.keys(
                                                                    validationErrors
                                                                ).forEach(
                                                                    (key) => {
                                                                        setFieldTouched(
                                                                            key,
                                                                            true,
                                                                            false
                                                                        );
                                                                    }
                                                                );
                                                            });
                                                    }}
                                                    onKeyDown={(event) => {
                                                        if (
                                                            event.key ===
                                                            'Enter'
                                                        ) {
                                                            event.preventDefault(); // Prevent form submit on Enter
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            styles.firstColumn
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.firstColumnheader
                                                            }
                                                        >
                                                            Submit Request
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginTop:
                                                                    '40px',
                                                                width: '100%',
                                                                maxWidth: 650,
                                                            }}
                                                        >
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <Form.Group className='mb-3'>
                                                                        <Form.Label
                                                                            style={{
                                                                                color: 'black',
                                                                            }}
                                                                            className='m-1'
                                                                        >
                                                                            First
                                                                            Name
                                                                            <span
                                                                                className='required'
                                                                                style={{
                                                                                    color: 'red',
                                                                                    marginLeft: 2,
                                                                                }}
                                                                            >
                                                                                *
                                                                            </span>
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type='text'
                                                                            name='firstName'
                                                                            placeholder='First Name'
                                                                            value={
                                                                                values.firstName
                                                                            }
                                                                            onChange={
                                                                                handleChange
                                                                            }
                                                                            isInvalid={
                                                                                errors.firstName
                                                                            }
                                                                        />
                                                                        <Form.Control.Feedback type='invalid'>
                                                                            {
                                                                                errors.firstName
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Form.Group className='mb-3'>
                                                                        <Form.Label
                                                                            style={{
                                                                                color: 'black',
                                                                            }}
                                                                            className='m-1'
                                                                        >
                                                                            Last
                                                                            Name
                                                                            <span
                                                                                className='required'
                                                                                style={{
                                                                                    color: 'red',
                                                                                    marginLeft: 2,
                                                                                }}
                                                                            >
                                                                                *
                                                                            </span>
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type='text'
                                                                            name='lastName'
                                                                            placeholder='Last Name'
                                                                            value={
                                                                                values.lastName
                                                                            }
                                                                            onChange={
                                                                                handleChange
                                                                            }
                                                                            isInvalid={
                                                                                errors.lastName
                                                                            }
                                                                            // style={{ padding: "8px" }}
                                                                        />
                                                                        <Form.Control.Feedback type='invalid'>
                                                                            {
                                                                                errors.lastName
                                                                            }
                                                                        </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Form.Group className='mb-3'>
                                                                <Form.Label
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                    className='m-1'
                                                                >
                                                                    Phone
                                                                    <span
                                                                        className='required'
                                                                        style={{
                                                                            color: 'red',
                                                                            marginLeft: 2,
                                                                        }}
                                                                    >
                                                                        *
                                                                    </span>
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type='text'
                                                                    name='phoneNumber'
                                                                    placeholder='Phone'
                                                                    value={
                                                                        values.phoneNumber
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    isInvalid={
                                                                        errors.phoneNumber
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type='invalid'>
                                                                    {
                                                                        errors.phoneNumber
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group className='mb-3'>
                                                                <Form.Label
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                    className='m-1'
                                                                >
                                                                    Email
                                                                    <span
                                                                        className='required'
                                                                        style={{
                                                                            color: 'red',
                                                                            marginLeft: 2,
                                                                        }}
                                                                    >
                                                                        *
                                                                    </span>
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type='email'
                                                                    name='email'
                                                                    placeholder='Email'
                                                                    value={
                                                                        values.email
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    isInvalid={
                                                                        errors.email
                                                                    }
                                                                    // style={{ padding: "8px" }}
                                                                />
                                                                <Form.Control.Feedback type='invalid'>
                                                                    {
                                                                        errors.email
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            <Form.Group className='mb-3'>
                                                                <Form.Label
                                                                    style={{
                                                                        color: 'black',
                                                                    }}
                                                                    className='m-1'
                                                                >
                                                                    Company
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type='text'
                                                                    name='companyName'
                                                                    placeholder='Company'
                                                                    value={
                                                                        values.companyName
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    // style={{ padding: "8px" }}
                                                                />
                                                            </Form.Group>

                                                            {isLoaded ? (
                                                                <StandaloneSearchBox
                                                                    onLoad={(
                                                                        ref
                                                                    ) =>
                                                                        (inputRef.current =
                                                                            ref)
                                                                    }
                                                                    onPlacesChanged={
                                                                        handlePlaceChanged
                                                                    }
                                                                >
                                                                    <Form.Group className='mb-3'>
                                                                        <Form.Label
                                                                            style={{
                                                                                color: 'black',
                                                                            }}
                                                                            className='m-1'
                                                                        >
                                                                            Address
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type='text'
                                                                            // name="street"
                                                                            placeholder='Street'
                                                                            // value={values.street}
                                                                            // onChange={handleChange}
                                                                            value={
                                                                                billingadd.street
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setbillingadd(
                                                                                    {
                                                                                        ...billingadd,
                                                                                        street: e
                                                                                            .target
                                                                                            .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                            // style={{ padding: "8px" }}
                                                                        />
                                                                    </Form.Group>
                                                                </StandaloneSearchBox>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <Form.Group className='mb-3'>
                                                                        <Form.Control
                                                                            type='text'
                                                                            // name="city"
                                                                            placeholder='City'
                                                                            // value={values.city}
                                                                            // onChange={handleChange}
                                                                            value={
                                                                                billingadd.city
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setbillingadd(
                                                                                    {
                                                                                        ...billingadd,
                                                                                        city: e
                                                                                            .target
                                                                                            .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                            // isInvalid={errors.item_name}
                                                                            // style={{ padding: "8px" }}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Form.Group className='mb-3'>
                                                                        <Form.Control
                                                                            type='text'
                                                                            name='state'
                                                                            placeholder='State'
                                                                            // value={values.state}
                                                                            // onChange={handleChange}
                                                                            value={
                                                                                billingadd.state
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setbillingadd(
                                                                                    {
                                                                                        ...billingadd,
                                                                                        state: e
                                                                                            .target
                                                                                            .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                            // isInvalid={errors.item_name}
                                                                            // style={{ padding: "8px" }}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <Form.Group className='mb-3'>
                                                                        <Form.Control
                                                                            type='text'
                                                                            name='zip'
                                                                            placeholder='Zip Code'
                                                                            // value={values.zip}
                                                                            // onChange={handleChange}
                                                                            value={
                                                                                billingadd.zip_code
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setbillingadd(
                                                                                    {
                                                                                        ...billingadd,
                                                                                        zip_code:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                            // isInvalid={errors.item_name}
                                                                            // style={{ padding: "8px" }}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Form.Group className='mb-3'>
                                                                        <Form.Control
                                                                            type='text'
                                                                            name='country'
                                                                            placeholder='Country'
                                                                            // value={values.country}
                                                                            // onChange={handleChange}
                                                                            value={
                                                                                billingadd.country
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setbillingadd(
                                                                                    {
                                                                                        ...billingadd,
                                                                                        country:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                            // isInvalid={errors.item_name}
                                                                            // style={{ padding: "8px" }}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            {custom_input.map(
                                                                (
                                                                    elem,
                                                                    index
                                                                ) => {
                                                                    let specialName = `${elem.input_type}${index}`;
                                                                    if (
                                                                        elem.input_type ===
                                                                        'header'
                                                                    ) {
                                                                        return (
                                                                            <div
                                                                                className='m-1'
                                                                                style={{
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize: 14,
                                                                                    color: 'black',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    elem.title
                                                                                }
                                                                            </div>
                                                                        );
                                                                    } else if (
                                                                        elem.input_type ===
                                                                        'multi_select'
                                                                    ) {
                                                                        return (
                                                                            <Form.Group className='mb-3'>
                                                                                <Form.Label
                                                                                    style={{
                                                                                        color: 'black',
                                                                                    }}
                                                                                    className='m-1'
                                                                                >
                                                                                    {
                                                                                        elem.title
                                                                                    }
                                                                                    {!elem.optional && (
                                                                                        <span
                                                                                            className='required'
                                                                                            style={{
                                                                                                color: 'red',
                                                                                                marginLeft: 2,
                                                                                            }}
                                                                                        >
                                                                                            *
                                                                                        </span>
                                                                                    )}
                                                                                </Form.Label>

                                                                                {elem.data_values.map(
                                                                                    (
                                                                                        option,
                                                                                        index
                                                                                    ) => (
                                                                                        <Form.Check
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            type='checkbox'
                                                                                            label={
                                                                                                option
                                                                                            }
                                                                                            name={
                                                                                                specialName
                                                                                            }
                                                                                            value={
                                                                                                option
                                                                                            }
                                                                                            checked={values[
                                                                                                specialName
                                                                                            ].includes(
                                                                                                option
                                                                                            )}
                                                                                            style={{
                                                                                                marginLeft: 10,
                                                                                            }}
                                                                                            onChange={(
                                                                                                e
                                                                                            ) => {
                                                                                                const selectedOptions =
                                                                                                    [
                                                                                                        ...values[
                                                                                                            specialName
                                                                                                        ],
                                                                                                    ]; // Copy current selected options
                                                                                                if (
                                                                                                    e
                                                                                                        .target
                                                                                                        .checked
                                                                                                ) {
                                                                                                    // Add option if checked
                                                                                                    selectedOptions.push(
                                                                                                        e
                                                                                                            .target
                                                                                                            .value
                                                                                                    );
                                                                                                } else {
                                                                                                    // Remove option if unchecked
                                                                                                    const indexToRemove =
                                                                                                        selectedOptions.indexOf(
                                                                                                            e
                                                                                                                .target
                                                                                                                .value
                                                                                                        );
                                                                                                    if (
                                                                                                        indexToRemove >
                                                                                                        -1
                                                                                                    ) {
                                                                                                        selectedOptions.splice(
                                                                                                            indexToRemove,
                                                                                                            1
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                                // Update Formik field with the new array
                                                                                                setFieldValue(
                                                                                                    specialName,
                                                                                                    selectedOptions
                                                                                                );
                                                                                            }}
                                                                                            isInvalid={
                                                                                                touched[
                                                                                                    specialName
                                                                                                ] &&
                                                                                                !!errors[
                                                                                                    specialName
                                                                                                ]
                                                                                            }
                                                                                        />
                                                                                    )
                                                                                )}
                                                                                <Form.Control.Feedback type='invalid'>
                                                                                    {
                                                                                        errors[
                                                                                            specialName
                                                                                        ]
                                                                                    }
                                                                                </Form.Control.Feedback>
                                                                            </Form.Group>
                                                                        );
                                                                    } else if (
                                                                        elem.input_type ===
                                                                        'single_select'
                                                                    ) {
                                                                        return (
                                                                            <Form.Group className='mb-3'>
                                                                                <Form.Label
                                                                                    style={{
                                                                                        color: 'black',
                                                                                    }}
                                                                                    className='m-1'
                                                                                >
                                                                                    {
                                                                                        elem.title
                                                                                    }
                                                                                    {!elem.optional ? (
                                                                                        <span
                                                                                            className='required'
                                                                                            style={{
                                                                                                color: 'red',
                                                                                                marginLeft: 2,
                                                                                            }}
                                                                                        >
                                                                                            *
                                                                                        </span>
                                                                                    ) : (
                                                                                        <>

                                                                                        </>
                                                                                    )}
                                                                                </Form.Label>
                                                                                {elem.data_values.map(
                                                                                    (
                                                                                        option,
                                                                                        index
                                                                                    ) => (
                                                                                        <Form.Check
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            type='radio'
                                                                                            label={
                                                                                                option
                                                                                            }
                                                                                            style={{
                                                                                                marginLeft: 10,
                                                                                            }}
                                                                                            name={
                                                                                                specialName
                                                                                            }
                                                                                            value={
                                                                                                option
                                                                                            }
                                                                                            checked={
                                                                                                values[
                                                                                                    specialName
                                                                                                ] &&
                                                                                                values[
                                                                                                    specialName
                                                                                                ].includes(
                                                                                                    option
                                                                                                )
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) => {
                                                                                                handleChange(
                                                                                                    {
                                                                                                        target: {
                                                                                                            name: e
                                                                                                                .target
                                                                                                                .name,
                                                                                                            value: [
                                                                                                                e
                                                                                                                    .target
                                                                                                                    .value,
                                                                                                            ],
                                                                                                        },
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                            isInvalid={
                                                                                                touched[
                                                                                                    specialName
                                                                                                ] &&
                                                                                                !!errors[
                                                                                                    specialName
                                                                                                ]
                                                                                            }
                                                                                        />
                                                                                    )
                                                                                )}
                                                                                <Form.Control.Feedback type='invalid'>
                                                                                    {
                                                                                        errors[
                                                                                            specialName
                                                                                        ]
                                                                                    }
                                                                                </Form.Control.Feedback>
                                                                            </Form.Group>
                                                                        );
                                                                    } else if (
                                                                        elem.input_type ===
                                                                        'short_free_form'
                                                                    ) {
                                                                        return (
                                                                            <Form.Group className='mb-3'>
                                                                                <Form.Label
                                                                                    style={{
                                                                                        color: 'black',
                                                                                    }}
                                                                                    className='m-1'
                                                                                >
                                                                                    {
                                                                                        elem.title
                                                                                    }
                                                                                    {!elem.optional ? (
                                                                                        <span
                                                                                            className='required'
                                                                                            style={{
                                                                                                color: 'red',
                                                                                                marginLeft: 2,
                                                                                            }}
                                                                                        >
                                                                                            *
                                                                                        </span>
                                                                                    ) : (
                                                                                        <>

                                                                                        </>
                                                                                    )}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type='text'
                                                                                    name={
                                                                                        specialName
                                                                                    }
                                                                                    placeholder='Type Here'
                                                                                    value={
                                                                                        values[
                                                                                            specialName
                                                                                        ] ||
                                                                                        ''
                                                                                    }
                                                                                    onChange={
                                                                                        handleChange
                                                                                    }
                                                                                    onBlur={
                                                                                        handleBlur
                                                                                    }
                                                                                    isInvalid={
                                                                                        touched[
                                                                                            specialName
                                                                                        ] &&
                                                                                        !!errors[
                                                                                            specialName
                                                                                        ]
                                                                                    }
                                                                                    // style={{ padding: "8px" }}
                                                                                />
                                                                                <Form.Control.Feedback type='invalid'>
                                                                                    {
                                                                                        errors[
                                                                                            specialName
                                                                                        ]
                                                                                    }
                                                                                </Form.Control.Feedback>
                                                                            </Form.Group>
                                                                        );
                                                                    } else if (
                                                                        elem.input_type ===
                                                                        'long_free_form'
                                                                    ) {
                                                                        return (
                                                                            <Form.Group className='mb-3'>
                                                                                <Form.Label
                                                                                    style={{
                                                                                        color: 'black',
                                                                                    }}
                                                                                    className='m-1'
                                                                                >
                                                                                    {
                                                                                        elem.title
                                                                                    }
                                                                                    {!elem.optional && (
                                                                                        <span
                                                                                            className='required'
                                                                                            style={{
                                                                                                color: 'red',
                                                                                                marginLeft: 2,
                                                                                            }}
                                                                                        >
                                                                                            *
                                                                                        </span>
                                                                                    )}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type='text'
                                                                                    name={
                                                                                        specialName
                                                                                    }
                                                                                    placeholder='Type Here'
                                                                                    as='textarea'
                                                                                    rows={
                                                                                        4
                                                                                    }
                                                                                    value={
                                                                                        values[
                                                                                            specialName
                                                                                        ] ||
                                                                                        ''
                                                                                    }
                                                                                    onChange={
                                                                                        handleChange
                                                                                    }
                                                                                    onBlur={
                                                                                        handleBlur
                                                                                    }
                                                                                    isInvalid={
                                                                                        touched[
                                                                                            specialName
                                                                                        ] &&
                                                                                        !!errors[
                                                                                            specialName
                                                                                        ]
                                                                                    }
                                                                                />
                                                                                <Form.Control.Feedback type='invalid'>
                                                                                    {
                                                                                        errors[
                                                                                            specialName
                                                                                        ]
                                                                                    }
                                                                                </Form.Control.Feedback>
                                                                            </Form.Group>
                                                                        );
                                                                    } else if (
                                                                        elem.input_type ===
                                                                        'image_upload'
                                                                    ) {
                                                                        return (
                                                                            <Form.Group className='mb-3'>
                                                                                <Form.Label 
                                                                                    style={{
                                                                                        color: 'black',
                                                                                    }}
                                                                                    className='m-1'>
                                                                                    {
                                                                                        elem.title
                                                                                    }
                                                                                    {!elem.optional && (
                                                                                        <span
                                                                                            className='required'
                                                                                            style={{
                                                                                                color: 'red',
                                                                                                marginLeft: 2,
                                                                                            }}
                                                                                        >
                                                                                            *
                                                                                        </span>
                                                                                    )}
                                                                                </Form.Label>

                                                                                <UploadHelper2
                                                                                    setFieldValue={
                                                                                        setFieldValue
                                                                                    }
                                                                                    currentFiles={
                                                                                        values[
                                                                                            specialName
                                                                                        ]
                                                                                    }
                                                                                    name={
                                                                                        specialName
                                                                                    }
                                                                                />
                                                                            </Form.Group>
                                                                        );
                                                                    }
                                                                }
                                                            )}

                                                            {!remove_input.includes(
                                                                'REQUEST_DESCRIPTION'
                                                            ) ? (
                                                                <Form.Group className='mb-3'>
                                                                    <Form.Label
                                                                        style={{
                                                                            color: 'black',
                                                                        }}
                                                                        className='m-1'
                                                                    >
                                                                        How can
                                                                        we help
                                                                        you?
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type='text'
                                                                        name='description'
                                                                        placeholder='Type Here'
                                                                        as='textarea'
                                                                        rows={4}
                                                                        value={
                                                                            values.description
                                                                        }
                                                                        onChange={
                                                                            handleChange
                                                                        }
                                                                        // isInvalid={errors.item_name}
                                                                        // style={{ padding: "8px" }}
                                                                    />
                                                                </Form.Group>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {!remove_input.includes(
                                                                'REQUEST_TIME'
                                                            ) ? (
                                                                <>
                                                                    <div
                                                                        className={
                                                                            styles.firstColumnheader2
                                                                        }
                                                                    >
                                                                        What
                                                                        time
                                                                        works
                                                                        best?
                                                                    </div>
                                                                    <Row
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            width: '100%',
                                                                            justifyContent:
                                                                                'space-between',
                                                                            marginRight: 0,
                                                                            marginLeft: 0,
                                                                            marginTop: 10,
                                                                            // backgroundColor: "red",
                                                                        }}
                                                                    >
                                                                        {slotsAvailable.map(
                                                                            (
                                                                                elem
                                                                            ) => {
                                                                                return (
                                                                                    <Col
                                                                                        style={{
                                                                                            padding: 5,
                                                                                        }}
                                                                                        xs={
                                                                                            6
                                                                                        }
                                                                                        sm={
                                                                                            3
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            className={
                                                                                                selectedSlot &&
                                                                                                Object.keys(
                                                                                                    selectedSlot
                                                                                                )
                                                                                                    .length !==
                                                                                                    0 &&
                                                                                                elem.start ===
                                                                                                    selectedSlot.start
                                                                                                    ? styles.selectedtimebox
                                                                                                    : styles.timebox
                                                                                            }
                                                                                            style={{
                                                                                                position:
                                                                                                    'relative',
                                                                                            }}
                                                                                            onClick={() =>
                                                                                                setSelectedSlot(
                                                                                                    elem
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {selectedSlot &&
                                                                                            Object.keys(
                                                                                                selectedSlot
                                                                                            )
                                                                                                .length !==
                                                                                                0 &&
                                                                                            elem.start ===
                                                                                                selectedSlot.start ? (
                                                                                                <div
                                                                                                    style={{
                                                                                                        backgroundColor:
                                                                                                            '#5CA5E0',
                                                                                                        // padding: 5,
                                                                                                        height: 20,
                                                                                                        width: 20,
                                                                                                        borderRadius: 50,
                                                                                                        display:
                                                                                                            'flex',
                                                                                                        justifyContent:
                                                                                                            'center',
                                                                                                        alignItems:
                                                                                                            'center',
                                                                                                        position:
                                                                                                            'absolute',
                                                                                                        top: -9,
                                                                                                        left: -9,
                                                                                                        // left: 0,
                                                                                                        // marginBottom: 80,
                                                                                                        // marginRight: 150,
                                                                                                    }}
                                                                                                >
                                                                                                    <Check
                                                                                                        size={
                                                                                                            10
                                                                                                        }
                                                                                                        strokeWidth={
                                                                                                            4
                                                                                                        }
                                                                                                        color='white'
                                                                                                    />
                                                                                                </div>
                                                                                            ) : (
                                                                                                <>

                                                                                                </>
                                                                                            )}
                                                                                            <Calendar
                                                                                                size={
                                                                                                    18
                                                                                                }
                                                                                                strokeWidth={
                                                                                                    2
                                                                                                }
                                                                                                className={
                                                                                                    selectedSlot &&
                                                                                                    Object.keys(
                                                                                                        selectedSlot
                                                                                                    )
                                                                                                        .length !==
                                                                                                        0 &&
                                                                                                    elem.start ===
                                                                                                        selectedSlot.start
                                                                                                        ? styles.timeboxiconselected
                                                                                                        : styles.timeboxicon
                                                                                                }
                                                                                            />
                                                                                            <div
                                                                                                style={{
                                                                                                    color: 'black',
                                                                                                    marginTop: 10,
                                                                                                    fontSize:
                                                                                                        '0.75rem',
                                                                                                    fontWeight: 600,
                                                                                                }}
                                                                                            >
                                                                                                {moment(
                                                                                                    new Date(
                                                                                                        elem.start
                                                                                                    )
                                                                                                ).format(
                                                                                                    'dddd'
                                                                                                )}
                                                                                            </div>
                                                                                            <div
                                                                                                style={{
                                                                                                    fontSize:
                                                                                                        '0.75rem',
                                                                                                }}
                                                                                            >
                                                                                                {moment(
                                                                                                    new Date(
                                                                                                        elem.start
                                                                                                    )
                                                                                                ).format(
                                                                                                    'ha'
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                );
                                                                            }
                                                                        )}
                                                                        <Col
                                                                            style={{
                                                                                padding: 5,
                                                                            }}
                                                                            xs={
                                                                                6
                                                                            }
                                                                            sm={
                                                                                3
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    selectedSlot &&
                                                                                    Object.keys(
                                                                                        selectedSlot
                                                                                    )
                                                                                        .length !==
                                                                                        0 &&
                                                                                    !slotsAvailable.some(
                                                                                        (
                                                                                            slot
                                                                                        ) =>
                                                                                            slot.start ===
                                                                                                selectedSlot.start &&
                                                                                            slot.end ===
                                                                                                selectedSlot.end
                                                                                    )
                                                                                        ? styles.selectedtimebox
                                                                                        : styles.timebox
                                                                                }
                                                                                style={{
                                                                                    position:
                                                                                        'relative',
                                                                                }}
                                                                                onClick={() => {
                                                                                    setLoadingmonthlyslots(
                                                                                        true
                                                                                    );
                                                                                    setModalseletedmonth(
                                                                                        modalselected
                                                                                    );
                                                                                    setPickModal(
                                                                                        true
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {selectedSlot &&
                                                                                Object.keys(
                                                                                    selectedSlot
                                                                                )
                                                                                    .length !==
                                                                                    0 &&
                                                                                !slotsAvailable.some(
                                                                                    (
                                                                                        slot
                                                                                    ) =>
                                                                                        slot.start ===
                                                                                            selectedSlot.start &&
                                                                                        slot.end ===
                                                                                            selectedSlot.end
                                                                                ) ? (
                                                                                    <div
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                '#5CA5E0',
                                                                                            // padding: 5,
                                                                                            height: 20,
                                                                                            width: 20,
                                                                                            borderRadius: 50,
                                                                                            display:
                                                                                                'flex',
                                                                                            justifyContent:
                                                                                                'center',
                                                                                            alignItems:
                                                                                                'center',
                                                                                            position:
                                                                                                'absolute',
                                                                                            top: -9,
                                                                                            left: -9,
                                                                                        }}
                                                                                    >
                                                                                        <Check
                                                                                            size={
                                                                                                10
                                                                                            }
                                                                                            strokeWidth={
                                                                                                4
                                                                                            }
                                                                                            color='white'
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                    <>

                                                                                    </>
                                                                                )}
                                                                                <Calendar
                                                                                    size={
                                                                                        18
                                                                                    }
                                                                                    strokeWidth={
                                                                                        2
                                                                                    }
                                                                                    className={
                                                                                        selectedSlot &&
                                                                                        Object.keys(
                                                                                            selectedSlot
                                                                                        )
                                                                                            .length !==
                                                                                            0 &&
                                                                                        !slotsAvailable.some(
                                                                                            (
                                                                                                slot
                                                                                            ) =>
                                                                                                slot.start ===
                                                                                                    selectedSlot.start &&
                                                                                                slot.end ===
                                                                                                    selectedSlot.end
                                                                                        )
                                                                                            ? styles.timeboxiconselected
                                                                                            : styles.timeboxicon
                                                                                    }
                                                                                />
                                                                                {selectedSlot &&
                                                                                Object.keys(
                                                                                    selectedSlot
                                                                                )
                                                                                    .length !==
                                                                                    0 &&
                                                                                !slotsAvailable.some(
                                                                                    (
                                                                                        slot
                                                                                    ) =>
                                                                                        slot.start ===
                                                                                            selectedSlot.start &&
                                                                                        slot.end ===
                                                                                            selectedSlot.end
                                                                                ) ? (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                color: 'black',
                                                                                                marginTop: 10,
                                                                                                fontSize:
                                                                                                    '0.75rem',
                                                                                                fontWeight: 600,
                                                                                            }}
                                                                                        >
                                                                                            {moment(
                                                                                                new Date(
                                                                                                    selectedSlot.start
                                                                                                )
                                                                                            ).format(
                                                                                                'dddd'
                                                                                            )}
                                                                                        </div>
                                                                                        <div
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    '0.75rem',
                                                                                            }}
                                                                                        >
                                                                                            {moment(
                                                                                                new Date(
                                                                                                    selectedSlot.start
                                                                                                )
                                                                                            ).format(
                                                                                                'ha'
                                                                                            )}
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <div
                                                                                        style={{
                                                                                            color: 'black',
                                                                                            marginTop: 10,
                                                                                            fontSize:
                                                                                                '0.75rem',
                                                                                            textAlign:
                                                                                                'center',
                                                                                            paddingRight: 2,
                                                                                            paddingLeft: 2,
                                                                                            fontSize: 11,
                                                                                            marginTop: 13,
                                                                                        }}
                                                                                    >
                                                                                        I
                                                                                        need
                                                                                        a
                                                                                        different
                                                                                        time
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <div
                                                                        className={
                                                                            selectedSlot ===
                                                                            null
                                                                                ? styles.selectedtimebox
                                                                                : styles.timebox
                                                                        }
                                                                        style={{
                                                                            position:
                                                                                'relative',
                                                                            flex: 1,
                                                                            margin: 5,
                                                                            width: 'auto',
                                                                        }}
                                                                        onClick={() =>
                                                                            setSelectedSlot(
                                                                                null
                                                                            )
                                                                        }
                                                                    >
                                                                        {selectedSlot ===
                                                                        null ? (
                                                                            <div
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        '#5CA5E0',
                                                                                    // padding: 5,
                                                                                    height: 20,
                                                                                    width: 20,
                                                                                    borderRadius: 50,
                                                                                    display:
                                                                                        'flex',
                                                                                    justifyContent:
                                                                                        'center',
                                                                                    alignItems:
                                                                                        'center',
                                                                                    position:
                                                                                        'absolute',
                                                                                    top: -9,
                                                                                    left: -9,
                                                                                }}
                                                                            >
                                                                                <Check
                                                                                    size={
                                                                                        10
                                                                                    }
                                                                                    strokeWidth={
                                                                                        4
                                                                                    }
                                                                                    color='white'
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <>

                                                                            </>
                                                                        )}
                                                                        <Calendar
                                                                            size={
                                                                                18
                                                                            }
                                                                            strokeWidth={
                                                                                2
                                                                            }
                                                                            className={
                                                                                selectedSlot ===
                                                                                null
                                                                                    ? styles.timeboxiconselected
                                                                                    : styles.timeboxicon
                                                                            }
                                                                        />
                                                                        <div
                                                                            style={{
                                                                                marginTop: 10,
                                                                                color: 'black',
                                                                                fontSize:
                                                                                    '0.75rem',
                                                                            }}
                                                                        >
                                                                            Schedule
                                                                            Time
                                                                            Later
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <div
                                                                className={
                                                                    styles.submitButtoncont
                                                                }
                                                            >
                                                                <Button
                                                                    className={
                                                                        styles.submitButton
                                                                    }
                                                                    // disabled={!isValid || !dirty}
                                                                    // disabled={!isValid || !dirty}
                                                                    type='save'
                                                                    disabled={
                                                                        saveloader
                                                                    }
                                                                >
                                                                    {!saveloader ? (
                                                                        <>
                                                                            <span>
                                                                                Send
                                                                                Request
                                                                            </span>
                                                                            <ArrowRight
                                                                                size={
                                                                                    16
                                                                                }
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '5px',
                                                                                }}
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                width: 100,
                                                                                display:
                                                                                    'flex',
                                                                                justifyContent:
                                                                                    'center',
                                                                                alignItems:
                                                                                    'center',
                                                                            }}
                                                                        >
                                                                            <Spinner
                                                                                size='sm'
                                                                                animation='border'
                                                                                variant='light'
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Col>
                                    {/* <Col lg={4}>
                    <div
                      className={styles.secondColumn}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <img
                          style={{ height: 200, objectFit: "cover" }}
                          src={bookingImg}
                          alt="Contact Image"
                        />
                        <div>
                          See Aha! software in action and get your questions
                          answered
                        </div>
                        <div style={{ width: "100%", marginTop: 20 }}>
                          {points.map((elem) => {
                            return (
                              <div className={styles.points}>
                                <CheckCircle
                                  style={{ marginRight: 15 }}
                                  size={18}
                                  color="#0173CE"
                                />
                                <div
                                  style={{
                                    fontSize: 12,
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {elem}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div style={{ fontSize: 11 }}>Powered by Bestmate</div>
                    </div>
                  </Col> */}
                                </Row>
                            </Card>
                        ) : (
                            <Card
                                className='border rounded-2'
                                style={{
                                    maxWidth: '880px',
                                    height: '500px',
                                    width: '100%',
                                    marginRight: 20,
                                    marginLeft: 20,
                                }}
                            >
                                <Loader />
                            </Card>
                        )}
                    </div>
                ) : (
                    <div className={styles.cardContainer}>
                        <Card
                            className='border rounded-2'
                            style={{
                                maxWidth: '680px',
                                width: '100%',
                                marginRight: 20,
                                marginLeft: 20,
                            }}
                        >
                            <Row>
                                <Col>
                                    <div className={styles.secondColumn}>
                                        <img
                                            style={{
                                                height: 120,
                                                objectFit: 'cover',
                                            }}
                                            src={confirmImg}
                                            alt='Contact Image'
                                        />
                                        <div className={styles.thankstext}>
                                            Thanks for your interest
                                        </div>
                                        <div>
                                            Our team will be in touch shortly
                                        </div>
                                        <div
                                            style={{
                                                width: '100%',
                                                marginTop: 20,
                                            }}
                                        ></div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                )}
            </div>
            <Modal
                show={pickModal}
                onHide={() => {
                    setLoadingmonthlyslots(false);
                    // setModalseletedmonth(new Date(modalselected));

                    setPickModal(!pickModal);
                }}
                centered
            >
                <Modal.Body className='text-center'>
                    <div
                        style={{
                            position: 'absolute',
                            right: 10,
                            top: 10,
                        }}
                    >
                        <X
                            size={30}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setLoadingmonthlyslots(false);
                                setPickModal(!pickModal);
                            }}
                        />
                    </div>
                    <div style={{ minHeight: 500, display: 'flex' }}>
                        <Row style={{ display: 'flex' }}>
                            <Col
                                style={
                                    {
                                        // display: "flex",
                                        // justifyContent: "center",
                                        // alignItems: "center",
                                        // width: "100%",
                                    }
                                }
                            >
                                <div
                                    style={{
                                        maxHeight: 300,
                                        maxWidth: 300,
                                        overflow: 'hidden',
                                    }}
                                >
                                    {loadingmonthlyslots && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                marginLeft: 145,
                                                marginTop: 170,
                                            }}
                                        >
                                            <Spinner
                                                animation='border'
                                                variant='dark'
                                                size='sm'
                                            />
                                        </div>
                                    )}
                                    <div>
                                        <DayPicker
                                            mode='single'
                                            selected={
                                                loadingmonthlyslots
                                                    ? ''
                                                    : modalselected
                                            }
                                            // onSelect={setSelected}
                                            // footer={footer}
                                            // use={renderDay}
                                            className='custom-day-picker'
                                            defaultMonth={modalselected}
                                            fromMonth={new Date()}
                                            onDayClick={(e) => {
                                                setModalSelected(e);
                                                console.log(e);
                                            }}
                                            onMonthChange={(e) => {
                                                setLoadingmonthlyslots(true);
                                                setModalseletedmonth(e);
                                                // setSlotsDailyCount([]);
                                                console.log(e);
                                            }}
                                            styles={
                                                {
                                                    // head_cell: {
                                                    //   width: "60px",
                                                    // },
                                                    // table: {
                                                    //   maxWidth: "100%",
                                                    // },
                                                    // day: {
                                                    //   margin: "auto",
                                                    // },
                                                }
                                            }
                                            disabled={disabledDays}
                                            // modifiers={{
                                            //   emptySlots: (date) => {
                                            //     const dateString = date.toISOString().split("T")[0];
                                            //     console.log(slotsCount, dateString);
                                            //     console.log(slotsCount[dateString]);
                                            //     return slotsCount[dateString]?.length === 0;
                                            //   },
                                            //   currentDate: new Date(),
                                            // }}
                                            // modifiersStyles={{
                                            //   emptySlots: {
                                            //     backgroundColor: "lightgreen",
                                            //   },
                                            //   currentDate: {
                                            //     border: "2px solid pink",
                                            //     position: "relative",
                                            //   },
                                            //   currentDate: {},
                                            // }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col
                                style={{
                                    width: 400,
                                }}
                            >
                                {/* <div>{loadingmonthlyslots.toString()}</div> */}
                                <div
                                    style={{
                                        padding: 20,
                                        width: '100%',
                                        maxWidth: 300,
                                    }}
                                >
                                    <div style={{ textAlign: 'left' }}>
                                        {moment(new Date(modalselected)).format(
                                            'dddd, MMMM DD'
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            overflow: 'scroll',
                                        }}
                                    >
                                        {selecteddaySlots.length !== 0 ? (
                                            selecteddaySlots.map((elem) => (
                                                <div
                                                    className={
                                                        selectedSlot &&
                                                        Object.keys(
                                                            selectedSlot
                                                        ).length !== 0 &&
                                                        elem.start ===
                                                            selectedSlot.start
                                                            ? styles.selectedtimeboxmodal
                                                            : styles.timeboxmodal
                                                    }
                                                    onClick={() => {
                                                        // const endtime = new Date(elem.endtime);
                                                        // endtime.setMinutes(endtime.getMinutes() - 1);
                                                        // const updated = endtime.getTime();
                                                        setSelectedSlot(elem);

                                                        setLoadingmonthlyslots(
                                                            false
                                                        );
                                                        setPickModal(
                                                            !pickModal
                                                        );
                                                    }}
                                                >
                                                    {moment(
                                                        new Date(elem.start)
                                                    ).format('hh:mm A')}
                                                </div>
                                            ))
                                        ) : (
                                            <div
                                                style={{
                                                    fontSize: 12,
                                                    textAlign: 'left',
                                                    marginTop: 10,
                                                    color: '#9c9a9a',
                                                }}
                                            >
                                                No Slots Available
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={showErrorModal}
                onHide={() => setShowErrorModal(false)}
                centered
                size='sm'
            >
                <Modal.Body>
                    <p style={{ textAlign: 'center',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                     }}>
                        There are is an error with your submission. Please make sure all required fields are filled out.
                    </p>

                    <Button
                        // variant='secondary'
                        onClick={() => setShowErrorModal(false)}
                        style={{ width: '100%', backgroundColor: '#0173ce' }}
                    >
                        Close
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default AddBooking;
